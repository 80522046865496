<template>
	<div class="goods-tabs">
		<div class="good-tabs-one">
			<div style="height: 890px; width: 38%"></div>
			<div :class="screenHeight + scrollTop <= tempsite1 ? 'untimefix' : ''" :style="
          screenHeight + scrollTop >= tempsite1
            ? `position: absolute;top:${mid}px`
            : ''
        ">
				<DetailsImages />
				<DetailsContactUs />
			</div>
			<div style="margin-left: 46px">
				<div class="goods-text-t">
					<p class="g-name">{{ detailData.productName }}</p>
					<p class="g-desc">
						{{ detailData.described }}
					</p>
					<p class="g-price">
						<span>￥</span>
						<span>{{ tapPane[currentIndex]?.configPrice.toFixed(2) }}</span>
						<!--<div class="g-price-1" @click="handleOpen">更高配置需求</div>
            <img src="./img/right.png" class="g-price-2" @click="handleOpen" />-->
					</p>
				</div>
				<!--<div class="coupon" @click="emitter.emit('couponPopup',{couponList:couponList})">-->
				<div class="coupon" @click="emitter.emit('couponPopup')" v-if="couponList.length">
					<div class="coupon-left">
						<template v-for="(item,index) in couponList">
							<div class="coupon-li" v-if="index < 3">
								满 {{item.amountOver}} 减 {{item.amount}}
							</div>
						</template>
					</div>
					<div class="coupon-right">
						<span>领优惠券</span>
						<img src="./img/right.png" @click="handleOpen" />
					</div>
				</div>
				<div class="commodity-tab">
					<div class="tags">
						<div class="dd">
							<a v-for="(item, index) in ArrayData" :key="item.id"
								:class="{ active: item.num === currentIndex }" href="javascript:"
								@click="updateReqParams(item.num, index)">{{ item.configType }}</a>
						</div>
					</div>
				</div>
				<div class="tabs-tab-two">
					<div class="tabs-tab-two-1" style="margin-bottom: 27px">配置详情</div>
					<div class="container">
						<!-- 配置信息 -->
						<div class="container-top-t">
							<div class="container-top-left" v-if="tapPane[currentIndex]?.configParent1ype01.length > 0">
								<div class="title-text">
									<span class="text-span"></span>
									<div class="container-top-l">系统核心</div>
								</div>
								<div class="container-top-l-lx">
									<ui>
										<li class="top-l-lx-cpu" v-for="(item, index) in tapPane[currentIndex]
                        ?.configParent1ype01" :key="item.id + index">
											<div>
												<div class="img-top-content">
													<div class="img-top-content-text">
														<p class="img-top-content-l">
															{{ item.typeName }}
														</p>
														<div class="img-top-content-r">
															<div class="img-top-text-r"
																v-if="item.optionalSupport == 2">
																<span class="img-top-content-r-text">{{
                                  item.materialName
                                }}</span>
															</div>
															<div v-if="item.optionalSupport == 1">
																<div class="img-top-content-m"
																	@click="handlePopUpList(item, index, 1)">
																	<div class="img-top-content-m-1">
																		{{ item.materialName }}
																	</div>
																	<div class="img-top-content-m-2">
																		×{{ item.count }}
																	</div>
																	<div>
																		<img class="img-top-content-m-3"
																			src="./image/todown.png" />
																	</div>
																</div>
															</div>
															<div v-if="
                                  item.countSupport == 3 &&
                                  item.optionalSupport == 2
                                ">
																<div class="num-inputs">
																	<button class="btn_minute"
																		@click="btnMinuteList(item, index, 1)">
																		-
																	</button>
																	<input class="btn-input" disabled type="number"
																		v-model="item.count" />
																	<button class="btn_add"
																		@click="btnAddList(item, index, 1)">
																		+
																	</button>
																</div>
															</div>
															<div class="input-number-num" v-if="
                                  item.optionalSupport == 2 &&
                                  item.countSupport == 4
                                ">
																<p class="num-span">×{{ item.count }}</p>
															</div>
														</div>
													</div>
													<div class="img-top-content-plus" v-if="
                              item.priceAdd > 0 &&
                              (item.countSupport == 3 ||
                                item.optionalSupport == 1)
                            ">
														{{
                              item.priceAdd
                                ? "RMB +" + item.priceAdd.toFixed(2)
                                : ""
                            }}
													</div>
													<div class="img-top-content-reduce" v-if="
                              item.priceAdd < 0 &&
                              (item.countSupport == 3 ||
                                item.optionalSupport == 1)
                            ">
														{{
                              item.priceAdd
                                ? "RMB " + item.priceAdd.toFixed(2)
                                : ""
                            }}
													</div>
												</div>
											</div>
										</li>
									</ui>
								</div>
							</div>
							<div class="container-top-left" v-if="tapPane[currentIndex]?.configParent2ype02.length > 0">
								<div class="title-text">
									<span class="text-span"></span>
									<div class="container-top-l">存储</div>
								</div>
								<div class="container-top-l-lx">
									<ui>
										<li class="top-l-lx-cpu" v-for="(item, index) in tapPane[currentIndex]
                        ?.configParent2ype02" :key="item.id + index">
											<div>
												<div class="img-top-content">
													<div class="img-top-content-text">
														<p class="img-top-content-l">
															{{ item.typeName }}
														</p>
														<div class="img-top-content-r">
															<div class="img-top-text-r"
																v-if="item.optionalSupport == 2">
																<span class="img-top-content-r-text">{{
                                  item.materialName
                                }}</span>
															</div>
															<div v-if="item.optionalSupport == 1">
																<div class="img-top-content-m"
																	@click="handlePopUpList(item, index, 2)">
																	<div :class="
                                      item.materialName != ''
                                        ? 'img-top-content-m-1'
                                        : 'img-top-content-m-1-1'
                                    ">
																		{{
                                      item.materialName != ""
                                        ? item.materialName
                                        : "请选择"
                                    }}
																	</div>
																	<div class="img-top-content-m-2"
																		v-if="item.count != 0">
																		×{{ item.count }}
																	</div>
																	<div class="img-top-content-m-2-2" v-else></div>
																	<div>
																		<img class="img-top-content-m-3"
																			src="./image/todown.png" />
																	</div>
																</div>
															</div>
															<div v-if="
                                  item.countSupport == 3 &&
                                  item.optionalSupport == 2
                                ">
																<div class="num-inputs">
																	<button class="btn_minute"
																		@click="btnMinuteList(item, index, 2)">
																		-
																	</button>
																	<input class="btn-input" disabled type="number"
																		v-model="item.count" />
																	<button class="btn_add"
																		@click="btnAddList(item, index, 2)">
																		+
																	</button>
																</div>
															</div>
															<div class="input-number-num" v-if="
                                  item.optionalSupport == 2 &&
                                  item.countSupport == 4
                                ">
																<p class="num-span">×{{ item.count }}</p>
															</div>
														</div>
													</div>
													<div class="img-top-content-plus" v-if="
                              item.priceAdd > 0 &&
                              (item.countSupport == 3 ||
                                item.optionalSupport == 1)
                            ">
														{{
                              item.priceAdd
                                ? "RMB +" + item.priceAdd.toFixed(2)
                                : ""
                            }}
													</div>
													<div class="img-top-content-reduce" v-if="
                              item.priceAdd < 0 &&
                              (item.countSupport == 3 ||
                                item.optionalSupport == 1)
                            ">
														{{
                              item.priceAdd
                                ? "RMB " + item.priceAdd.toFixed(2)
                                : ""
                            }}
													</div>
												</div>
											</div>
										</li>
									</ui>
								</div>
							</div>
							<div class="container-top-left" v-if="tapPane[currentIndex]?.configParent3ype03.length > 0">
								<div class="title-text">
									<span class="text-span"></span>
									<div class="container-top-l">散热</div>
								</div>
								<div class="container-top-l-lx">
									<ui>
										<li class="top-l-lx-cpu" v-for="(item, index) in tapPane[currentIndex]
                        ?.configParent3ype03" :key="item.id + index">
											<div>
												<div class="img-top-content">
													<div class="img-top-content-text">
														<p class="img-top-content-l">
															{{ item.typeName }}
														</p>
														<div class="img-top-content-r">
															<div class="img-top-text-r"
																v-if="item.optionalSupport == 2">
																<span class="img-top-content-r-text">{{
                                  item.materialName
                                }}</span>
															</div>
															<div v-if="item.optionalSupport == 1">
																<div class="img-top-content-m"
																	@click="handlePopUpList(item, index, 3)">
																	<div class="img-top-content-m-1">
																		{{ item.materialName }}
																	</div>
																	<div class="img-top-content-m-2">
																		×{{ item.count }}
																	</div>
																	<div>
																		<img class="img-top-content-m-3"
																			src="./image/todown.png" />
																	</div>
																</div>
															</div>
															<div v-if="
                                  item.countSupport == 3 &&
                                  item.optionalSupport == 2
                                ">
																<div class="num-inputs">
																	<button class="btn_minute"
																		@click="btnMinuteList(item, index, 3)">
																		-
																	</button>
																	<input class="btn-input" disabled type="number"
																		v-model="item.count" />
																	<button class="btn_add"
																		@click="btnAddList(item, index, 3)">
																		+
																	</button>
																</div>
															</div>
															<div class="input-number-num" v-if="
                                  item.optionalSupport == 2 &&
                                  item.countSupport == 4
                                ">
																<p class="num-span">×{{ item.count }}</p>
															</div>
														</div>
													</div>
													<div class="img-top-content-plus" v-if="
                              item.priceAdd > 0 &&
                              (item.countSupport == 3 ||
                                item.optionalSupport == 1)
                            ">
														{{
                              item.priceAdd
                                ? "RMB +" + item.priceAdd.toFixed(2)
                                : ""
                            }}
													</div>
													<div class="img-top-content-reduce" v-if="
                              item.priceAdd < 0 &&
                              (item.countSupport == 3 ||
                                item.optionalSupport == 1)
                            ">
														{{
                              item.priceAdd
                                ? "RMB " + item.priceAdd.toFixed(2)
                                : ""
                            }}
													</div>
												</div>
											</div>
										</li>
									</ui>
								</div>
							</div>
							<div class="container-top-left" v-if="tapPane[currentIndex]?.configParent4ype04.length > 0">
								<div class="title-text">
									<span class="text-span"></span>
									<div class="container-top-l">配件</div>
								</div>
								<div class="container-top-l-lx">
									<ui>
										<li class="top-l-lx-cpu" v-for="(item, index) in tapPane[currentIndex]
                        ?.configParent4ype04" :key="item.id + index">
											<div>
												<div class="img-top-content">
													<div class="img-top-content-text">
														<p class="img-top-content-l">
															{{ item.typeName }}
														</p>
														<div class="img-top-content-r">
															<div class="img-top-text-r"
																v-if="item.optionalSupport == 2">
																<span class="img-top-content-r-text">{{
                                  item.materialName
                                }}</span>
															</div>
															<div v-if="item.optionalSupport == 1">
																<div class="img-top-content-m"
																	@click="handlePopUpList(item, index, 4)">
																	<div class="img-top-content-m-1">
																		{{ item.materialName }}
																	</div>
																	<div class="img-top-content-m-2">
																		×{{ item.count }}
																	</div>
																	<div>
																		<img class="img-top-content-m-3"
																			src="./image/todown.png" />
																	</div>
																</div>
															</div>
															<div v-if="
                                  item.countSupport == 3 &&
                                  item.optionalSupport == 2
                                ">
																<div class="num-inputs">
																	<button class="btn_minute"
																		@click="btnMinuteList(item, index, 4)">
																		-
																	</button>
																	<input class="btn-input" disabled type="number"
																		v-model="item.count" />
																	<button class="btn_add"
																		@click="btnAddList(item, index, 4)">
																		+
																	</button>
																</div>
															</div>
															<div class="input-number-num" v-if="
                                  item.optionalSupport == 2 &&
                                  item.countSupport == 4
                                ">
																<p class="num-span">×{{ item.count }}</p>
															</div>
														</div>
													</div>
													<div class="img-top-content-plus" v-if="
                              item.priceAdd > 0 &&
                              (item.countSupport == 3 ||
                                item.optionalSupport == 1)
                            ">
														{{
                              item.priceAdd
                                ? "RMB +" + item.priceAdd.toFixed(2)
                                : ""
                            }}
													</div>
													<div class="img-top-content-reduce" v-if="
                              item.priceAdd < 0 &&
                              (item.countSupport == 3 ||
                                item.optionalSupport == 1)
                            ">
														{{
                              item.priceAdd
                                ? "RMB " + item.priceAdd.toFixed(2)
                                : ""
                            }}
													</div>
												</div>
											</div>
										</li>
									</ui>
								</div>
							</div>
							<div class="container-top-left" v-if="tapPane[currentIndex]?.configParent5ype05.length > 0">
								<div class="title-text">
									<span class="text-span"></span>
									<div class="container-top-l">软件</div>
								</div>
								<div class="container-top-l-lx">
									<ui>
										<li class="top-l-lx-cpu" v-for="(item, index) in tapPane[currentIndex]
                        ?.configParent5ype05" :key="item.id + index">
											<div>
												<div class="img-top-content">
													<div class="img-top-content-text">
														<p class="img-top-content-l">
															{{ item.typeName }}
														</p>
														<div class="img-top-content-r">
															<div class="img-top-text-r"
																v-if="item.optionalSupport == 2">
																<span class="img-top-content-r-text">{{
                                  item.materialName
                                }}</span>
															</div>
															<div v-if="item.optionalSupport == 1">
																<div class="img-top-content-m"
																	@click="handlePopUpList(item, index, 5)">
																	<div class="img-top-content-m-1">
																		{{ item.materialName }}
																	</div>
																	<div class="img-top-content-m-2">
																		×{{ item.count }}
																	</div>
																	<div>
																		<img class="img-top-content-m-3"
																			src="./image/todown.png" />
																	</div>
																</div>
															</div>
															<div v-if="
                                  item.countSupport == 3 &&
                                  item.optionalSupport == 2
                                ">
																<div class="num-inputs">
																	<button class="btn_minute"
																		@click="btnMinuteList(item, index, 5)">
																		-
																	</button>
																	<input class="btn-input" disabled type="number"
																		v-model="item.count" />
																	<button class="btn_add"
																		@click="btnAddList(item, index, 5)">
																		+
																	</button>
																</div>
															</div>
															<div class="input-number-num" v-if="
                                  item.optionalSupport == 2 &&
                                  item.countSupport == 4
                                ">
																<p class="num-span">×{{ item.count }}</p>
															</div>
														</div>
													</div>
													<div class="img-top-content-plus" v-if="
                              item.priceAdd > 0 &&
                              (item.countSupport == 3 ||
                                item.optionalSupport == 1)
                            ">
														{{
                              item.priceAdd
                                ? "RMB +" + item.priceAdd.toFixed(2)
                                : ""
                            }}
													</div>
													<div class="img-top-content-reduce" v-if="
                              item.priceAdd < 0 &&
                              (item.countSupport == 3 ||
                                item.optionalSupport == 1)
                            ">
														{{
                              item.priceAdd
                                ? "RMB " + item.priceAdd.toFixed(2)
                                : ""
                            }}
													</div>
												</div>
											</div>
										</li>
									</ui>
								</div>
							</div>
							<div class="container-top-left" v-if="tapPane[currentIndex]?.configParent6ype06.length > 0">
								<div class="title-text">
									<span class="text-span"></span>
									<div class="container-top-l">服务</div>
								</div>
								<div class="container-top-l-lx">
									<ui>
										<li class="top-l-lx-cpu" v-for="(item, index) in tapPane[currentIndex]
                        ?.configParent6ype06" :key="item.id + index">
											<div>
												<div class="img-top-content">
													<div class="img-top-content-text">
														<p class="img-top-content-l">
															{{ item.typeName }}
														</p>
														<div class="img-top-content-r">
															<div class="img-top-text-r"
																v-if="item.optionalSupport == 2">
																<span class="img-top-content-r-text">{{
                                  item.materialName
                                }}</span>
															</div>
															<div v-if="item.optionalSupport == 1">
																<div class="img-top-content-m"
																	@click="handlePopUpList(item, index, 6)">
																	<div :class="
                                      item.materialName != ''
                                        ? 'img-top-content-m-1'
                                        : 'img-top-content-m-1-1'
                                    ">
																		{{
                                      item.materialName != ""
                                        ? item.materialName
                                        : "请选择"
                                    }}
																	</div>
																	<div class="img-top-content-m-2"
																		v-if="item.count != 0">
																		×{{ item.count }}
																	</div>
																	<div class="img-top-content-m-2-2" v-else></div>
																	<div>
																		<img class="img-top-content-m-3"
																			src="./image/todown.png" />
																	</div>
																</div>
															</div>
															<div v-if="
                                  item.countSupport == 3 &&
                                  item.optionalSupport == 2
                                ">
																<div class="num-inputs">
																	<button class="btn_minute"
																		@click="btnMinuteList(item, index, 6)">
																		-
																	</button>
																	<input class="btn-input" disabled type="number"
																		v-model="item.count" />
																	<button class="btn_add"
																		@click="btnAddList(item, index, 6)">
																		+
																	</button>
																</div>
															</div>
															<div class="input-number-num" v-if="
                                  item.optionalSupport == 2 &&
                                  item.countSupport == 4
                                ">
																<p class="num-span">×{{ item.count }}</p>
															</div>
														</div>
													</div>
													<div class="img-top-content-plus" v-if="
                              item.priceAdd > 0 &&
                              (item.countSupport == 3 ||
                                item.optionalSupport == 1)
                            ">
														{{
                              item.priceAdd
                                ? "RMB +" + item.priceAdd.toFixed(2)
                                : ""
                            }}
													</div>
													<div class="img-top-content-reduce" v-if="
                              item.priceAdd < 0 &&
                              (item.countSupport == 3 ||
                                item.optionalSupport == 1)
                            ">
														{{
                              item.priceAdd
                                ? "RMB " + item.priceAdd.toFixed(2)
                                : ""
                            }}
													</div>
												</div>
											</div>
										</li>
									</ui>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="firmCustomize">
					<div class="firmCustomize-1" v-if="productCustomizedMaterialPOS.length > 0">
						<div style="display: flex">
							<img src="./img/title-1.png" class="firmCustomize-2-1" />
							<div class="firmCustomize-2">宁美企业客制</div>
						</div>
						<div :class="
                item.changeState == 1 && item.checkState == 1
                  ? 'firmCustomize-3-plus'
                  : 'firmCustomize-3'
              " :style="
                item.type == 0 && item.changeState == 1 ? 'height: 187px;' : ''
              " v-for="(item, index) in productCustomizedMaterialPOS">
							<img :src="
                  item.checkState == 1
                    ? require('./img/ischeck.png')
                    : require('./img/check.png')
                " class="firmCustomize-4" @click="isShowCheck(item, index)" />
							<div :class="
                  item.changeState == 1 && item.checkState == 1
                    ? 'firmCustomize-5-plus'
                    : 'firmCustomize-5'
                ">
								{{ item.materialName }}
							</div>
							<div class="firmCustomize-6">{{ item.standard }}</div>
							<div class="firmCustomize-6-1" v-if="item.type == 1 && item.changeState == 1">
								<div class="firmCustomize-6-1-1">
									<img :src="item.customerLogos[0].img" style="width: 48px; height: 48px" />
								</div>
								<div class="firmCustomize-6-1-2">
									{{ item.originalFilename }}
								</div>
							</div>
							<div class="firmCustomize-8" @click="customMade(item.id, index)">
								{{
                  item.changeState == 0
                    ? "我要定制"
                    : item.type == 0
                    ? "修改图片"
                    : "修改选项"
                }}
							</div>
							<div class="firmCustomize-9" v-if="item.changeState == 1 && item.type == 0">
								<div class="firmCustomize-9-1">
									<div class="firmCustomize-9-2">
										<img :src="item.customerLogos[0].img" style="width: 48px; height: 48px" />
									</div>
									<div class="firmCustomize-9-3">
										{{ item.originalFilename }}
									</div>
								</div>
								<div style="height: 70px; display: flex">
									<div class="firmCustomize-9-4" v-for="(
                      littleImg, index
                    ) in item.customizedMaterialSamplePOS">
										<img :src="littleImg.samplePictures[0].img" style="width: 48px; height: 48px" />
										<div class="firmCustomize-9-5">
											{{ littleImg.samplePictureName }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="choose">
						<div class="choose-1">已选购</div>
						<div class="choose-2">
							<div class="choose-3">{{ ArrayData[tempindex]?.configType }}</div>
							<div class="choose-4">
								￥{{
                  (tapPane[currentIndex]?.configPrice - tempMany).toFixed(2)
                }}
							</div>
						</div>
						<div class="choose-2" v-for="(item, index) in showShops">
							<div class="choose-3">{{ item.materialName }}</div>
							<div class="choose-4">￥{{ item.price }}</div>
						</div>
					</div>
				</div>
				<div class="g-price-btn">
					<!-- 配置即时价格 -->
					<div class="g-price">
						<p><span class="configuration">配置即时价格</span></p>
						<p>
							<span style="line-height: 20px">{{
                tapPane[currentIndex]?.configPrice.toFixed(2)
              }}</span>
						</p>
					</div>
					<!-- 立即订购 -->
					<div class="box-btn">
						<div class="box-btn-2" @click="addshopping(tapPane[currentIndex])">加入购物车</div>
						<el-button class="box-btn-l" @click="immediate(tapPane[currentIndex])">立即订购</el-button>
						<!-- <img
              class="box-btn-r"
              :src="require('../../../assets/images/dtt2.gif')"
              @click="handleOpen"
            /> -->
					</div>
					<!-- 现在订购 -->
					<div class="order-t">
						<span><img src="../../../assets/banner/jiantou.png"
								alt="" /></span><span>现在订购，产品将于5个工作日内送货上门！</span>
					</div>
					<!-- 正品货源 -->
					<div class="order-list" id="unsite1">
						<ul v-for="(item, index) in order" :key="item.id + index">
							<li>
								<img src="../../../assets/banner/gouxuan.png" alt="" />
								<span>{{ item.text }}</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div id="detailSite1"></div>
		<div class="goods-tabs-1" id="detailSite">
			<!-- <DetailsEvaluation /> -->
			<el-tabs @tab-click="onChecked" v-model="activeName" type="card">
				<el-tab-pane label="应用场景" name="detail1" v-if="application || applicationVideo"></el-tab-pane>
				<el-tab-pane label="商品详情" name="detail2" v-if="commodityImg"></el-tab-pane>
				<el-tab-pane label="规格参数" name="detail3" v-if="normsPicture"></el-tab-pane>
				<el-tab-pane label="售后政策" name="detail4" v-if="policyPicture"></el-tab-pane>
				<el-tab-pane label="客户使用感受" name="detail5" v-if="feelData"></el-tab-pane>
			</el-tabs>
			<div>
				<div id="detail1" v-if="application || applicationVideo" class="scrollClass">
					<DetailsScene :application="application" :applicationVideo="applicationVideo"
						:applicationImg="applicationImg"></DetailsScene>
				</div>
				<div id="detail2" v-if="commodityImg" class="scrollClass">
					<DetailsCommodity :commodityImg="commodityImg"></DetailsCommodity>
				</div>
				<div id="detail3" v-if="normsPicture" class="scrollClass">
					<div class="shop-container">
						<ui v-for="item in normsPicture" :key="item.index">
							<li><img :src="item.img" /></li>
						</ui>
					</div>
				</div>
				<div id="detail4" v-if="policyPicture" class="scrollClass">
					<div class="shop-container">
						<ui v-for="item in policyPicture" :key="item.index">
							<li><img :src="item.img" /></li>
						</ui>
					</div>
				</div>
				<div id="detail5" v-if="feelData" class="scrollClass">
					<div class="detailCenter">应用场景</div>
					<DetailsFeel @changeClick="changeValue" />
				</div>
			</div>
			<!--<div class="goods-tabs" id="detailSite">
        <el-tabs v-model="activeName" type="card">
          <el-tab-pane
            label="应用场景"
            name="1"
            v-if="application || applicationVideo"
          >
            <DetailsScene
              :application="application"
              :applicationVideo="applicationVideo"
              :applicationImg="applicationImg"
            ></DetailsScene>
          </el-tab-pane>
          <el-tab-pane label="商品详情" name="2" v-if="commodityImg">
            <DetailsCommodity :commodityImg="commodityImg"></DetailsCommodity>
          </el-tab-pane>
          <el-tab-pane label="规格参数" name="3" v-if="normsPicture">
            <div class="shop-container">
              <ui v-for="item in normsPicture" :key="item.index"
                ><li><img :src="item.img" /></li
              ></ui>
            </div>
          </el-tab-pane>
          <el-tab-pane label="售后政策" name="4" v-if="policyPicture">
            <div class="shop-container">
              <ui v-for="item in policyPicture" :key="item.index"
                ><li><img :src="item.img" /></li
              ></ui>
            </div>
          </el-tab-pane>
          <el-tab-pane label="客户使用感受" name="5" v-if="feelData">
            <DetailsFeel @changeClick="changeValue"/>
          </el-tab-pane>
        </el-tabs>
      </div>-->
		</div>
	</div>
	<div class="dialog-list">
		<el-dialog style="width: 63%; height: 85%; --el-dialog-margin-top: 10vh" v-model="popUpList" title="物料选配">
			<div class="border"></div>
			<div class="border-content">
				<div class="T-J"><img src="./img/tj.png" /></div>
				<div class="content" v-for="(item, index) in optionalMaterial" :key="item.id + index"
					@click="selectH(item, index)" :class="{ active: index == isCheck }">
					<span class="imgVal"></span>

					<div class="content-text">
						<p class="content-text-1">
							{{ item.materialName }}
						</p>
						<p class="content-text-2">
							{{ item.standard }}
						</p>
						<p class="content-text-3">
							<span class="content-text-3-1">选购数量</span><span
								class="content-text-3-2">x{{ item.count }}</span>
						</p>
					</div>
					<div class="content-num">
						<span class="num-input-1" v-if="item.countSupport == 4">x{{ item.count }}</span>
						<div class="num-input" v-if="item.countSupport == 3" @click.stop="noteActive">
							<button class="btn_minute" :disabled="index != isCheck" @click="btnMinute(item, index)">
								-
							</button>
							<input class="btn-input" disabled type="number" v-model="item.count" />
							<button class="btn_add" :disabled="index != isCheck" @click="btnAdd(item, index)">
								+
							</button>
						</div>
						<span class="kernel-i-1" v-if="item.priceAdd > 0"><span style="
                  font-family: PingFang SC, Helvetica Neue, Helvetica,
                    Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
                ">RMB</span>
							{{ item.priceAdd.toFixed(2) }}</span>
						<span class="kernel-i-2" v-if="item.priceAdd < 0"><span style="
                  font-family: PingFang SC, Helvetica Neue, Helvetica,
                    Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
                ">RMB</span>
							{{ item.priceAdd.toFixed(2) }}</span>
					</div>
				</div>
			</div>
			<div class="black">
				<p class="black-1">
					<span class="black-1-1">选择该配置将增加预算</span><span class="black-1-2" v-if="priceAddVal >= 0"><span
							style="
                font-family: PingFang SC, Helvetica Neue, Helvetica,
                  Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
              ">RMB</span>
						+{{ priceAddVal.toFixed(2) }}</span><span class="black-1-3" v-if="priceAddVal < 0"><span style="
                font-family: PingFang SC, Helvetica Neue, Helvetica,
                  Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
              ">RMB</span>
						{{ priceAddVal.toFixed(2) }}</span>
				</p>
				<div class="black-4-4">
					<p class="black-3" @click="numSelected(signType1, signType2, priceAddVal)"
						v-if="signType1 || signType2">
						取消选中
					</p>
					<p class="black-2" @click="numericalSelection(priceAddVal)">
						我选好了
					</p>
				</div>
			</div>
		</el-dialog>
	</div>
	<Information v-model="showOpen" />
	<HostCustome v-model="showCustom" />
	<div class="app-header-sticky" v-if="scrollTop > swarpSite" :class="{ show: scrollTop > swarpSite }">
		<div v-if="scrollTop > swarpSite"></div>
		<div class="container-btn">
			<div class="container-left">
				<span>{{ detailData.productName
          }}{{ ArrayData[tempindex].configType }}</span>
			</div>
			<div class="container-middle">
				<div v-if="application || applicationVideo" class="container-middle-1"
					@click="topsenceSwtich('detail1')" :class="{ active: checkedActiveName == 'detail1' }">
					应用场景
				</div>
				<div v-if="commodityImg" class="container-middle-1" @click="topsenceSwtich('detail2')"
					:class="{ active: checkedActiveName == 'detail2' }">
					商品详情
				</div>
				<div v-if="normsPicture" class="container-middle-1" @click="topsenceSwtich('detail3')"
					:class="{ active: checkedActiveName == 'detail3' }">
					规格参数
				</div>
				<div v-if="policyPicture" class="container-middle-1" @click="topsenceSwtich('detail4')"
					:class="{ active: checkedActiveName == 'detail4' }">
					售后政策
				</div>
				<div v-if="feelData" class="container-middle-1" @click="topsenceSwtich('detail5')"
					:class="{ active: checkedActiveName == 'detail5' }" style="width: 90px">
					客户使用感受
				</div>
			</div>
			<div class="container-right">
				<p>配置即时价格:</p>
				<p>
					<span style="font-size: 14px; padding-right: 3px">￥</span>
					<span>{{ tapPane[currentIndex]?.configPrice }}</span>
				</p>
				<div class="r-btn">
					<el-button @click="immediate(tapPane[currentIndex])">立即订购</el-button>
				</div>
			</div>
		</div>
	</div>
	<CouponPopup />
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onUnmounted
	} from "vue";
	import DetailTotalWeight from "./DetailTotalWeight.vue";
	import DetailsPolicy from "./DetailsPolicy.vue";
	import useScrollTop from "@/hooks/useScrollTops";
	import DetailsImages from "./DetailsImages.vue";
	import DetailsContactUs from "./DetailsContactUs.vue";
	import DetailsEvaluation from "./DetailsEvaluation";
	import {
		listProductStandardNew,
		getCustomizedMaterialById,
		checkCustomizedMaterial,
		addShop,
		clickSafe
	} from "@/api/details";
	import {
		baiduClue
	} from "@/api/shop"
	import HostCustome from "@/components/hostCustomer";
	import {
		useRoute,
		useRouter
	} from "vue-router";
	import DetailsScene from "./DetailsScene.vue";
	import DetailsCommodity from "./DetailsCommodity.vue";
	import DetailsFeel from "./DetailsFeel.vue";
	import emitter from "@/utils/eventBus";
	import {
		ElMessage
	} from "element-plus";
	import Information from "../../../components/directives/Information";
	import {
		Encrypt,
		Decrypt
	} from "@/utils/aes";
	import {
		couponQueryList
	} from "@/api/coupon";
	import HomeBackup from "@/views/home/components/HomeBackup.vue";
	import CouponPopup from "@/views/detailsGoods/components/CouponPopup.vue";
	const currentIndex = ref(0);
	const scrollTop = useScrollTop();
	const route = useRoute();
	const activeTags = ref("first");

	const tapPane = ref([]);
	const detailData = ref({});
	const specificationImg = ref([]); // 规格参数
	const afterSaleImg = ref([]);
	const giftData = ref([]);
	const ArrayData = ref([]);
	const daList = ref({});
	const houseNoData = ref(route.query.houseNo);
	const popUpList = ref(false); // 选配弹窗
	const optionalMaterial = ref([]);
	const priceAddVal = ref(0);
	const isCheck = ref(0); // 弹窗选中样式
	const list1 = ref(); // 弹窗选中样式
	const numSelected = (signType1, signType2, amount) => {
		if (signType1) {
			tapPane.value[currentIndex.value].configParent2ype02[
				list1.value
			].priceAdd = 0;
			tapPane.value[currentIndex.value].configParent2ype02[
				list1.value
			].materialName = "";
			tapPane.value[currentIndex.value].configParent2ype02[list1.value].count = 1;
			tapPane.value[currentIndex.value].configParent2ype02[
				list1.value
			].defaultSelect = 0;
			tapPane.value[currentIndex.value].configParent2ype02[
				list1.value
			].materialId = "";
			if (
				tapPane.value[currentIndex.value].configParent2ype02[list1.value]
				.tempConfigPrice != undefined
			) {
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice -
					tapPane.value[currentIndex.value].configParent2ype02[list1.value]
					.tempConfigPrice;
				tapPane.value[currentIndex.value].configParent2ype02[
					list1.value
				].tempConfigPrice = 0;
				tapPane.value[currentIndex.value].configParent2ype02[
					list1.value
				].count = 0;
				priceAddVal.value = 0;
			}
		}
		if (signType2) {
			tapPane.value[currentIndex.value].configParent6ype06[
				list1.value
			].priceAdd = 0;
			tapPane.value[currentIndex.value].configParent6ype06[
				list1.value
			].materialName = "";
			tapPane.value[currentIndex.value].configParent6ype06[list1.value].count = 1;
			tapPane.value[currentIndex.value].configParent6ype06[
				list1.value
			].defaultSelect = 0;
			tapPane.value[currentIndex.value].configParent6ype06[
				list1.value
			].materialId = "";
			if (
				tapPane.value[currentIndex.value].configParent6ype06[list1.value]
				.tempConfigPrice != undefined
			) {
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice -
					tapPane.value[currentIndex.value].configParent6ype06[list1.value]
					.tempConfigPrice;
				tapPane.value[currentIndex.value].configParent6ype06[
					list1.value
				].tempConfigPrice = 0;
				tapPane.value[currentIndex.value].configParent6ype06[
					list1.value
				].count = 0;
				priceAddVal.value = 0;
			}
		}
		popUpList.value = false;
	};
	const btnAddList = (item, id, inx) => {
		select.value = inx;
		if (item.count >= 10) {
			return;
		} else {
			item.count++;
			if (select.value == 1) {
				tapPane.value[currentIndex.value].configParent1ype01[id].priceAdd =
					tapPane.value[currentIndex.value].configParent1ype01[id].priceAdd +
					tapPane.value[currentIndex.value].configParent1ype01[id].priceAdjust;
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice +
					tapPane.value[currentIndex.value].configParent1ype01[id].priceAdjust;
			} else if (select.value == 2) {
				tapPane.value[currentIndex.value].configParent2ype02[id].priceAdd =
					tapPane.value[currentIndex.value].configParent2ype02[id].priceAdd +
					tapPane.value[currentIndex.value].configParent2ype02[id].priceAdjust;
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice +
					tapPane.value[currentIndex.value].configParent2ype02[id].priceAdjust;
			} else if (select.value == 3) {
				tapPane.value[currentIndex.value].configParent3ype03[id].priceAdd =
					tapPane.value[currentIndex.value].configParent3ype03[id].priceAdd +
					tapPane.value[currentIndex.value].configParent3ype03[id].priceAdjust;
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice +
					tapPane.value[currentIndex.value].configParent3ype03[id].priceAdjust;
			} else if (select.value == 4) {
				tapPane.value[currentIndex.value].configParent4ype04[id].priceAdd =
					tapPane.value[currentIndex.value].configParent4ype04[id].priceAdd +
					tapPane.value[currentIndex.value].configParent4ype04[id].priceAdjust;
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice +
					tapPane.value[currentIndex.value].configParent4ype04[id].priceAdjust;
			} else if (select.value == 5) {
				tapPane.value[currentIndex.value].configParent5ype05[id].priceAdd =
					tapPane.value[currentIndex.value].configParent5ype05[id].priceAdd +
					tapPane.value[currentIndex.value].configParent5ype05[id].priceAdjust;
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice +
					tapPane.value[currentIndex.value].configParent5ype05[id].priceAdjust;
			} else if (select.value == 6) {
				tapPane.value[currentIndex.value].configParent6ype06[id].priceAdd =
					tapPane.value[currentIndex.value].configParent6ype06[id].priceAdd +
					tapPane.value[currentIndex.value].configParent6ype06[id].priceAdjust;
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice +
					tapPane.value[currentIndex.value].configParent6ype06[id].priceAdjust;
			}
		}
	};
	const btnMinuteList = (item, id, inx) => {
		select.value = inx;
		if (item.count <= 1) {
			return;
		} else {
			item.count--;
			if (select.value == 1) {
				tapPane.value[currentIndex.value].configParent1ype01[id].priceAdd =
					tapPane.value[currentIndex.value].configParent1ype01[id].priceAdd -
					tapPane.value[currentIndex.value].configParent1ype01[id].priceAdjust;
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice -
					tapPane.value[currentIndex.value].configParent1ype01[id].priceAdjust;
			} else if (select.value == 2) {
				tapPane.value[currentIndex.value].configParent2ype02[id].priceAdd =
					tapPane.value[currentIndex.value].configParent2ype02[id].priceAdd -
					tapPane.value[currentIndex.value].configParent2ype02[id].priceAdjust;
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice -
					tapPane.value[currentIndex.value].configParent2ype02[id].priceAdjust;
			} else if (select.value == 3) {
				tapPane.value[currentIndex.value].configParent3ype03[id].priceAdd =
					tapPane.value[currentIndex.value].configParent3ype03[id].priceAdd -
					tapPane.value[currentIndex.value].configParent3ype03[id].priceAdjust;
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice -
					tapPane.value[currentIndex.value].configParent3ype03[id].priceAdjust;
			} else if (select.value == 4) {
				tapPane.value[currentIndex.value].configParent4ype04[id].priceAdd =
					tapPane.value[currentIndex.value].configParent4ype04[id].priceAdd -
					tapPane.value[currentIndex.value].configParent4ype04[id].priceAdjust;
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice -
					tapPane.value[currentIndex.value].configParent4ype04[id].priceAdjust;
			} else if (select.value == 5) {
				tapPane.value[currentIndex.value].configParent5ype05[id].priceAdd =
					tapPane.value[currentIndex.value].configParent5ype05[id].priceAdd -
					tapPane.value[currentIndex.value].configParent5ype05[id].priceAdjust;
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice -
					tapPane.value[currentIndex.value].configParent5ype05[id].priceAdjust;
			} else if (select.value == 6) {
				tapPane.value[currentIndex.value].configParent6ype06[id].priceAdd =
					tapPane.value[currentIndex.value].configParent6ype06[id].priceAdd -
					tapPane.value[currentIndex.value].configParent6ype06[id].priceAdjust;
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice -
					tapPane.value[currentIndex.value].configParent6ype06[id].priceAdjust;
			}
		}
	};
	const btnMinute = (item, index) => {
		if (item.count <= 1) {
			return;
		} else {
			item.count--;
			optionalMaterial.value[index].priceAdd =
				optionalMaterial.value[index].priceAdd -
				optionalMaterial.value[index].priceAdjust;
			priceAddVal.value = priceAddVal.value - item.priceAdjust;
		}
	};
	const btnAdd = (item, index) => {
		if (item.count >= 10) {
			return;
		} else {
			item.count++;
			optionalMaterial.value[index].priceAdd =
				optionalMaterial.value[index].priceAdd +
				optionalMaterial.value[index].priceAdjust;
			priceAddVal.value = priceAddVal.value + item.priceAdjust;
		}
	};
	const tempValue = ref();
	const selectH = (item, index) => {
		if (select.value == 6 && list1.value != 0) {
			if (index == isCheck.value) {
				isCheck.value = undefined;
				priceAddVal.value = 0;
				return;
			}
		}
		if (select.value == 2 && list1.value != 1 && list1.value != 0) {
			if (index == isCheck.value) {
				isCheck.value = undefined;
				priceAddVal.value = 0;
				return;
			}
		}
		isCheck.value = index;
		priceAddVal.value = item.priceAdd;
		tempValue.value = item;
	};
	const normsPicture = ref([]);
	const policyPicture = ref([]);
	const numericalSelection = (data) => {
		if (select.value == 1) {
			tapPane.value[currentIndex.value].configParent1ype01[list1.value].priceAdd =
				data;
			tapPane.value[currentIndex.value].configParent1ype01[
				list1.value
			].materialCode = tempValue.value.materialCode;
			if (
				tapPane.value[currentIndex.value].configParent1ype01[list1.value]
				.tempConfigPrice != undefined
			) {
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice -
					tapPane.value[currentIndex.value].configParent1ype01[list1.value]
					.tempConfigPrice +
					data;
				tapPane.value[currentIndex.value].configParent1ype01[
					list1.value
				].tempConfigPrice = data;
				tapPane.value[currentIndex.value].configParent1ype01[
					list1.value
				].materialId = tempValue.value.materialId;
				tapPane.value[currentIndex.value].configParent1ype01[
					list1.value
				].materialName = tempValue.value.materialName;
				tapPane.value[currentIndex.value].configParent1ype01[list1.value].count =
					tempValue.value.count;
				tapPane.value[currentIndex.value].configParent1ype01[
					list1.value
				].defaultSelect = isCheck.value;
			} else {
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice + data;
				tapPane.value[currentIndex.value].configParent1ype01[
					list1.value
				].tempConfigPrice = data;
				tapPane.value[currentIndex.value].configParent1ype01[
					list1.value
				].materialId = tempValue.value.materialId;
				tapPane.value[currentIndex.value].configParent1ype01[
					list1.value
				].materialName = tempValue.value.materialName;
				tapPane.value[currentIndex.value].configParent1ype01[list1.value].count =
					tempValue.value.count;
				tapPane.value[currentIndex.value].configParent1ype01[
					list1.value
				].defaultSelect = isCheck.value;
			}
		} else if (select.value == 2) {
			tapPane.value[currentIndex.value].configParent2ype02[list1.value].priceAdd =
				data;
			tapPane.value[currentIndex.value].configParent2ype02[
				list1.value
			].materialCode = tempValue.value.materialCode;
			if (
				tapPane.value[currentIndex.value].configParent2ype02[list1.value]
				.tempConfigPrice != undefined
			) {
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice -
					tapPane.value[currentIndex.value].configParent2ype02[list1.value]
					.tempConfigPrice +
					data;
				tapPane.value[currentIndex.value].configParent2ype02[
					list1.value
				].tempConfigPrice = data;
				tapPane.value[currentIndex.value].configParent2ype02[list1.value].count =
					tempValue.value.count;
				tapPane.value[currentIndex.value].configParent2ype02[
					list1.value
				].defaultSelect = isCheck.value;
				tapPane.value[currentIndex.value].configParent2ype02[
					list1.value
				].materialId = tempValue.value.materialId;
				tapPane.value[currentIndex.value].configParent2ype02[
					list1.value
				].materialName = tempValue.value.materialName;
				if (select.value == 2 && list1.value != 1 && isCheck.value >= 0) {
					tapPane.value[currentIndex.value].configParent2ype02[
						list1.value
					].materialId = tempValue.value.materialId;
					tapPane.value[currentIndex.value].configParent2ype02[
						list1.value
					].materialName = tempValue.value.materialName;
				} else if (list1.value > 1 && isCheck.value == undefined) {
					tapPane.value[currentIndex.value].configParent2ype02[
						list1.value
					].materialId = "";
					tapPane.value[currentIndex.value].configParent2ype02[
						list1.value
					].materialName = "";
					tapPane.value[currentIndex.value].configParent2ype02[
						list1.value
					].count = 0;
				}
			} else {
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice + data;
				tapPane.value[currentIndex.value].configParent2ype02[
					list1.value
				].tempConfigPrice = data;
				tapPane.value[currentIndex.value].configParent2ype02[
					list1.value
				].materialName = tempValue.value.materialName;
				tapPane.value[currentIndex.value].configParent2ype02[
					list1.value
				].materialId = tempValue.value.materialId;
				tapPane.value[currentIndex.value].configParent2ype02[list1.value].count =
					tempValue.value.count;
				tapPane.value[currentIndex.value].configParent2ype02[
					list1.value
				].defaultSelect = isCheck.value;
				if (select.value == 2 && list1.value != 1 && isCheck.value >= 0) {
					tapPane.value[currentIndex.value].configParent2ype02[
						list1.value
					].materialId = tempValue.value.materialId;
					tapPane.value[currentIndex.value].configParent2ype02[
						list1.value
					].materialName = tempValue.value.materialName;
				} else if (list1.value > 1 && isCheck.value == undefined) {
					tapPane.value[currentIndex.value].configParent2ype02[
						list1.value
					].materialId = "";
					tapPane.value[currentIndex.value].configParent2ype02[
						list1.value
					].materialName = "";
					tapPane.value[currentIndex.value].configParent2ype02[
						list1.value
					].count = 0;
				}
			}
		} else if (select.value == 3) {
			tapPane.value[currentIndex.value].configParent3ype03[list1.value].priceAdd =
				data;
			tapPane.value[currentIndex.value].configParent3ype03[
				list1.value
			].materialCode = tempValue.value.materialCode;
			if (
				tapPane.value[currentIndex.value].configParent3ype03[list1.value]
				.tempConfigPrice != undefined
			) {
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice -
					tapPane.value[currentIndex.value].configParent3ype03[list1.value]
					.tempConfigPrice +
					data;
				tapPane.value[currentIndex.value].configParent3ype03[
					list1.value
				].tempConfigPrice = data;
				tapPane.value[currentIndex.value].configParent3ype03[
					list1.value
				].materialId = tempValue.value.materialId;
				tapPane.value[currentIndex.value].configParent3ype03[
					list1.value
				].materialName = tempValue.value.materialName;
				tapPane.value[currentIndex.value].configParent3ype03[list1.value].count =
					tempValue.value.count;
				tapPane.value[currentIndex.value].configParent3ype03[
					list1.value
				].defaultSelect = isCheck.value;
			} else {
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice + data;
				tapPane.value[currentIndex.value].configParent3ype03[
					list1.value
				].tempConfigPrice = data;
				tapPane.value[currentIndex.value].configParent3ype03[
					list1.value
				].materialId = tempValue.value.materialId;
				tapPane.value[currentIndex.value].configParent3ype03[
					list1.value
				].materialName = tempValue.value.materialName;
				tapPane.value[currentIndex.value].configParent3ype03[list1.value].count =
					tempValue.value.count;
				tapPane.value[currentIndex.value].configParent3ype03[
					list1.value
				].defaultSelect = isCheck.value;
			}
		} else if (select.value == 4) {
			tapPane.value[currentIndex.value].configParent4ype04[
				list1.value
			].materialCode = tempValue.value.materialCode;
			tapPane.value[currentIndex.value].configParent4ype04[list1.value].priceAdd =
				data;
			if (
				tapPane.value[currentIndex.value].configParent4ype04[list1.value]
				.tempConfigPrice != undefined
			) {
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice -
					tapPane.value[currentIndex.value].configParent4ype04[list1.value]
					.tempConfigPrice +
					data;
				tapPane.value[currentIndex.value].configParent4ype04[
					list1.value
				].tempConfigPrice = data;
				tapPane.value[currentIndex.value].configParent4ype04[
					list1.value
				].materialId = tempValue.value.materialId;
				tapPane.value[currentIndex.value].configParent4ype04[
					list1.value
				].materialName = tempValue.value.materialName;
				tapPane.value[currentIndex.value].configParent4ype04[list1.value].count =
					tempValue.value.count;
				tapPane.value[currentIndex.value].configParent4ype04[
					list1.value
				].defaultSelect = isCheck.value;
			} else {
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice + data;
				tapPane.value[currentIndex.value].configParent4ype04[
					list1.value
				].tempConfigPrice = data;
				tapPane.value[currentIndex.value].configParent4ype04[
					list1.value
				].materialId = tempValue.value.materialId;
				tapPane.value[currentIndex.value].configParent4ype04[
					list1.value
				].materialName = tempValue.value.materialName;
				tapPane.value[currentIndex.value].configParent4ype04[list1.value].count =
					tempValue.value.count;
				tapPane.value[currentIndex.value].configParent4ype04[
					list1.value
				].defaultSelect = isCheck.value;
			}
		} else if (select.value == 5) {
			tapPane.value[currentIndex.value].configParent5ype05[list1.value].priceAdd =
				data;
			tapPane.value[currentIndex.value].configParent5ype05[
				list1.value
			].materialCode = tempValue.value.materialCode;
			if (
				tapPane.value[currentIndex.value].configParent5ype05[list1.value]
				.tempConfigPrice != undefined
			) {
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice -
					tapPane.value[currentIndex.value].configParent5ype05[list1.value]
					.tempConfigPrice +
					data;
				tapPane.value[currentIndex.value].configParent5ype05[
					list1.value
				].tempConfigPrice = data;
				tapPane.value[currentIndex.value].configParent5ype05[
					list1.value
				].materialId = tempValue.value.materialId;
				tapPane.value[currentIndex.value].configParent5ype05[
					list1.value
				].materialName = tempValue.value.materialName;
				tapPane.value[currentIndex.value].configParent5ype05[list1.value].count =
					tempValue.value.count;
				tapPane.value[currentIndex.value].configParent5ype05[
					list1.value
				].defaultSelect = isCheck.value;
			} else {
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice + data;
				tapPane.value[currentIndex.value].configParent5ype05[
					list1.value
				].tempConfigPrice = data;
				tapPane.value[currentIndex.value].configParent5ype05[
					list1.value
				].materialId = tempValue.value.materialId;
				tapPane.value[currentIndex.value].configParent5ype05[
					list1.value
				].materialName = tempValue.value.materialName;
				tapPane.value[currentIndex.value].configParent5ype05[list1.value].count =
					tempValue.value.count;
				tapPane.value[currentIndex.value].configParent5ype05[
					list1.value
				].defaultSelect = isCheck.value;
			}
		} else if (select.value == 6) {
			tapPane.value[currentIndex.value].configParent6ype06[list1.value].priceAdd =
				data;
			tapPane.value[currentIndex.value].configParent6ype06[
				list1.value
			].materialCode = tempValue.value.materialCode;
			if (
				tapPane.value[currentIndex.value].configParent6ype06[list1.value]
				.tempConfigPrice != undefined
			) {
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice -
					tapPane.value[currentIndex.value].configParent6ype06[list1.value]
					.tempConfigPrice +
					data;
				tapPane.value[currentIndex.value].configParent6ype06[
					list1.value
				].tempConfigPrice = data;
				tapPane.value[currentIndex.value].configParent6ype06[list1.value].count =
					tempValue.value.count;
				tapPane.value[currentIndex.value].configParent6ype06[
					list1.value
				].defaultSelect = isCheck.value;
				tapPane.value[currentIndex.value].configParent6ype06[
					list1.value
				].materialId = tempValue.value.materialId;
				tapPane.value[currentIndex.value].configParent6ype06[
					list1.value
				].materialName = tempValue.value.materialName;
				if (select.value == 6 && list1.value != 0 && isCheck.value >= 0) {
					tapPane.value[currentIndex.value].configParent6ype06[
						list1.value
					].materialId = tempValue.value.materialId;
					tapPane.value[currentIndex.value].configParent6ype06[
						list1.value
					].materialName = tempValue.value.materialName;
				} else if (list1.value > 0 && isCheck.value == undefined) {
					tapPane.value[currentIndex.value].configParent6ype06[
						list1.value
					].materialId = "";
					tapPane.value[currentIndex.value].configParent6ype06[
						list1.value
					].materialName = "";
					tapPane.value[currentIndex.value].configParent6ype06[
						list1.value
					].count = 0;
				}
			} else {
				tapPane.value[currentIndex.value].configPrice =
					tapPane.value[currentIndex.value].configPrice + data;
				tapPane.value[currentIndex.value].configParent6ype06[
					list1.value
				].tempConfigPrice = data;
				tapPane.value[currentIndex.value].configParent6ype06[list1.value].count =
					tempValue.value.count;
				tapPane.value[currentIndex.value].configParent6ype06[
					list1.value
				].defaultSelect = isCheck.value;
				tapPane.value[currentIndex.value].configParent6ype06[
					list1.value
				].materialId = tempValue.value.materialId;
				tapPane.value[currentIndex.value].configParent6ype06[
					list1.value
				].materialName = tempValue.value.materialName;
				if (select.value == 6 && list1.value != 0 && isCheck.value >= 0) {
					tapPane.value[currentIndex.value].configParent6ype06[
						list1.value
					].materialId = tempValue.value.materialId;
					tapPane.value[currentIndex.value].configParent6ype06[
						list1.value
					].materialName = tempValue.value.materialName;
				} else if (list1.value > 0 && isCheck.value == undefined) {
					tapPane.value[currentIndex.value].configParent6ype06[
						list1.value
					].materialId = "";
					tapPane.value[currentIndex.value].configParent6ype06[
						list1.value
					].materialName = "";
					tapPane.value[currentIndex.value].configParent6ype06[
						list1.value
					].count = 0;
				}
			}
		}
		popUpList.value = false;
	};
	//console.log(houseNoData.value, "cecece");
	const applicationImg = ref(null);
	const applicationVideo = ref(null);
	const feelData = ref([]);
	const changeValue = (feel) => {
		if (feel == undefined) {
			feelData.value = feel;
		}
	};
	let flag = ref(false);
	// 点击悬浮标签
	const topsenceSwtich = (index) => {
		flag.value = true;
		checkedActiveName.value = index;
		//document.getElementById('detailSite1').scrollIntoView()
		document.documentElement.scrollTop =
			document.getElementById(`${index}`).offsetTop - 200;
		activeName.value = index;
	};
	const commodityImg = ref([]);
	const application = ref([]);
	const loadTopSite = () => {
		const detailSite = document.getElementById("detailSite");
		setTimeout(function() {
			swarpSite.value = detailSite.offsetTop - 200;
		}, 500);
	};
	// 监听页面滚动距离，改变悬浮tab标签选中的标签
	const checkedActiveName = ref("detail1"); // // 监听页面滚动距离，改变tab标签选中的标签
	const alterActiveName = () => {
		if (flag.value) {
			flag.value = false;
			return;
		} else {
			let scrollClassName = document.querySelectorAll(".scrollClass");
			for (let i = scrollClassName.length - 1; i >= 0; i--) {
				//console.log(document.documentElement.scrollTop,"document.documentElement.scrollTop")
				//console.log(scrollClassName[i].offsetTop,`scrollClassName[${i}].offsetTop`)
				if (
					document.documentElement.scrollTop >=
					scrollClassName[i].offsetTop - 400
				) {
					checkedActiveName.value = scrollClassName[i].id;
					activeName.value = scrollClassName[i].id;
					break;
				}
			}
		}
	};
	// 标签选中时触发
	const onChecked = (TabsPaneContext) => {
		document.documentElement.scrollTop =
			document.getElementById(`${TabsPaneContext.props.name}`).offsetTop - 200;
		checkedActiveName.value = TabsPaneContext.props.name;
		activeName.value = TabsPaneContext.props.name;
	};
	const productCustomizedMaterialPOS = ref([]);
	const showShops = ref([]);
	const tempMany = ref(0);
	const showCustom = ref(false);
	const getCustomer = (productId = route.query.id) => {
		getCustomizedMaterialById(productId).then((res) => {
			productCustomizedMaterialPOS.value =
				res.data.productCustomizedMaterialPOList;
		});
	};
	emitter.on("confimCustomClose", (val) => {
		showCustom.value = false;
	});
	emitter.on("customClose", (val) => {
		showShops.value = [];
		tempMany.value = 0;
		if (val.customClose) {
			productCustomizedMaterialPOS.value = val.productCustomizedMaterialPOS;
			productCustomizedMaterialPOS.value.forEach((element) => {
				if (element.checkState == 1) {
					showShops.value.push(element);
					tempMany.value += element.price;
				}
			});
			if (val.configPrice) {
				tapPane.value[currentIndex.value].configPrice = val.configPrice;
			}
			showCustom.value = false;
		}
	});
	const customMade = (id, inx) => {
		const token = localStorage.getItem("token");
		if (token) {
			showCustom.value = true;
			emitter.emit("customId", {
				customId: id,
				configPrice: tapPane.value[currentIndex.value].configPrice,
				productList: productCustomizedMaterialPOS.value,
				proId: inx,
			});
		} else {
			emitter.emit("openLogin", {
				openLogin: true
			});
		}
	};
	const addshopping = (data) => {
		// 百度埋点
		if (sessionStorage.getItem("bd_vid")) {
			const dateTime = Date.now();
			const timestamp = Math.floor(dateTime / 1000);
			const buriedData = [{
				logidUrl: window.location.href,
				newType: 46,
				convertTime: timestamp
			}]
			baiduClue(buriedData);
		}
		let primevalData = {
			configId: data.configId,
			materials: [],
			customerMaterials: productCustomizedMaterialPOS.value,
			productAmount: 1,
			productId: route.query.id,
			price: data.configPrice
		}
		data.configParent1ype01.forEach(item => {
			if (item.count > 0) {
				primevalData.materials.push({
					amount: item.count,
					materialCode: item.materialCode
				})
			}
		})
		data.configParent2ype02.forEach(item => {
			if (item.count > 0) {
				primevalData.materials.push({
					amount: item.count,
					materialCode: item.materialCode
				})
			}
		})
		data.configParent3ype03.forEach(item => {
			if (item.count > 0) {
				primevalData.materials.push({
					amount: item.count,
					materialCode: item.materialCode
				})
			}
		})
		data.configParent4ype04.forEach(item => {
			if (item.count > 0) {
				primevalData.materials.push({
					amount: item.count,
					materialCode: item.materialCode
				})
			}
		})
		data.configParent5ype05.forEach(item => {
			if (item.count > 0) {
				primevalData.materials.push({
					amount: item.count,
					materialCode: item.materialCode
				})
			}
		})
		data.configParent6ype06.forEach(item => {
			if (item.count > 0) {
				primevalData.materials.push({
					amount: item.count,
					materialCode: item.materialCode
				})
			}
		})
		let aesData = Encrypt(JSON.stringify(primevalData));
		let datas = {
			encryptData: aesData,
		};
		addShop(datas).then((res) => {
			if (res.code == 200) {
				ElMessage({
					message: "已添加购物车",
					type: "success",
				});
				emitter.emit("addshop", {
					addshop: true
				})
			} else {
				ElMessage({
					message: res.message,
					type: "error",
				});
			}
		})
	}
	const isShowCheck = (val, inx) => {
		const token = localStorage.getItem("token");
		if (token) {
			if (val.changeState == 1) {
				val.checkState = val.checkState == 0 ? 1 : 0;
				showShops.value = [];
				let data = {
					id: val.id,
					checkState: val.checkState,
					configPrice: tapPane.value[currentIndex.value].configPrice,
				};
				checkCustomizedMaterial(data).then((res) => {
					if (res.code == 200) {
						productCustomizedMaterialPOS.value =
							res.data.productCustomizedMaterialPOList;
						tapPane.value[currentIndex.value].configPrice = res.data.configPrice;
						tempMany.value = 0;
						productCustomizedMaterialPOS.value.forEach((element) => {
							if (element.checkState == 1) {
								showShops.value.push(element);
								tempMany.value += element.price;
							}
						});
					}
				});
			} else {
				showCustom.value = true;
				emitter.emit("customId", {
					customId: val.id,
					configPrice: tapPane.value[currentIndex.value].configPrice,
					productList: productCustomizedMaterialPOS.value,
					proId: inx,
				});
			}
		} else {
			emitter.emit("openLogin", {
				openLogin: true
			});
		}
	};
	const getDetailGoods = async (productId = route.query.id) => {
		try {
			const res = await listProductStandardNew(productId);
			let data = Decrypt(res.data);
			let secret = JSON.parse(data);
			//console.log(secret, "系统数据");
			tapPane.value = secret.configTypeNewVOList;
			detailData.value = secret.productInfoPO;
			giftData.value = secret.productGiftPOS;
			specificationImg.value = secret.productInfoPO.normsPictures;
			afterSaleImg.value = secret.productInfoPO.policyPictures;
			if (secret.productInfoPO.reviews) {
				applicationVideo.value = secret.productInfoPO.reviews[0]?.img;
			}
			commodityImg.value = secret.productInfoPO.detailPictures;
			application.value = secret.productInfoPO.behaviourPictures;
			applicationImg.value = secret.productInfoPO.cover;
			normsPicture.value = secret.productInfoPO.normsPictures;
			policyPicture.value = secret.productInfoPO.policyPictures;

			//记录点击数量
			let referer = document.referrer
			if (referer == '') {
				referer = '直接访问'
			} else if (referer.indexOf('?') != -1) {
				referer = referer.substring(0, referer.indexOf('?'))
			}
			let clickData = {
				productId: detailData.value.id,
				productName: detailData.value.productName,
				source: referer,
				client: 'PC'
			}
			let resp = await clickSafe(clickData)




			// 默认选中标签
			if (application.value) {
				activeName.value = "detail1";
			} else if (commodityImg.value) {
				activeName.value = "detail2";
			} else if (normsPicture.value) {
				activeName.value = "detail3";
			} else if (policyPicture.value) {
				activeName.value = "detail4";
			} else {
				activeName.value = "detail5";
			}
			ArrayData.value = tapPane.value.map((item, index) => {
				return Object.assign(item, {
					num: index++
				});
			});
			// 带配置名跳转到详情页
			if (houseNoData.value) {
				daList.value = ArrayData.value.filter(
					(item) => item.configType === houseNoData.value
				);
				currentIndex.value = daList.value[0].num;
			}
		} catch (error) {
			console.log(error);
		}
	};

	onMounted(() => {
		getDetailGoods();
		loadTopSite();
		getCustomer();
		unlocation();
		getCouponList();
		window.addEventListener("scroll", alterActiveName);
		// 百度埋点
		const url = window.location.href;
		if (url.search("bd_vid") != -1) {
			const bd_vid = url.match(/bd_vid=(\S*)/)[1];
			sessionStorage.setItem("bd_vid", bd_vid)
		}
	});
	onUnmounted(() => {
		if (sessionStorage.getItem("bd_vid")) {
			sessionStorage.removeItem("bd_vid")
		}
	})
	const unmiddle = ref(0);
	const screenHeight = ref(0);
	const tempsite1 = ref(0);
	const mid = ref(100);
	const unlocation = () => {
		setTimeout(function() {
			tempsite1.value = document.getElementById("unsite1").offsetTop;
			if (screenHeight.value > 1000) {
				mid.value = tempsite1.value - 1096;
			} else {
				mid.value = tempsite1.value - 796;
			}
		}, 1500);
		screenHeight.value = document.documentElement.clientHeight;
	};
	const couponList = ref([]);
	// 查询优惠券
	const getCouponList = () => {
		couponQueryList().then((res) => {
			if (res.code == 200) {
				couponList.value = res.data
			}
		}).catch((err) => {
			console.log(err)
		})
	};

	const activeName = ref("");
	const select = ref(1);
	const handlePopUpList = (val, inx, idx) => {
		optionalMaterial.value = val.productMaterialPOS;
		list1.value = inx;
		select.value = idx;
		popUpList.value = true;
		if (idx == 1) {
			isCheck.value = tapPane.value[currentIndex.value].configParent1ype01[inx]
				.defaultSelect ?
				tapPane.value[currentIndex.value].configParent1ype01[inx].defaultSelect :
				0;
			tempValue.value = optionalMaterial.value[isCheck.value];
			priceAddVal.value = tempValue.value.priceAdd;
		} else if (idx == 2) {
			isCheck.value = tapPane.value[currentIndex.value].configParent2ype02[inx]
				.defaultSelect ?
				tapPane.value[currentIndex.value].configParent2ype02[inx].defaultSelect :
				0;
			tempValue.value = optionalMaterial.value[isCheck.value];
			priceAddVal.value = tempValue.value.priceAdd;
		} else if (idx == 3) {
			isCheck.value = tapPane.value[currentIndex.value].configParent3ype03[inx]
				.defaultSelect ?
				tapPane.value[currentIndex.value].configParent3ype03[inx].defaultSelect :
				0;
			tempValue.value = optionalMaterial.value[isCheck.value];
			priceAddVal.value = tempValue.value.priceAdd;
		} else if (idx == 4) {
			isCheck.value = tapPane.value[currentIndex.value].configParent4ype04[inx]
				.defaultSelect ?
				tapPane.value[currentIndex.value].configParent4ype04[inx].defaultSelect :
				0;
			tempValue.value = optionalMaterial.value[isCheck.value];
			priceAddVal.value = tempValue.value.priceAdd;
		} else if (idx == 5) {
			isCheck.value = tapPane.value[currentIndex.value].configParent5ype05[inx]
				.defaultSelect ?
				tapPane.value[currentIndex.value].configParent5ype05[inx].defaultSelect :
				0;
			tempValue.value = optionalMaterial.value[isCheck.value];
			priceAddVal.value = tempValue.value.priceAdd;
		} else if (idx == 6) {
			isCheck.value = tapPane.value[currentIndex.value].configParent6ype06[inx]
				.defaultSelect ?
				tapPane.value[currentIndex.value].configParent6ype06[inx].defaultSelect :
				0;
			tempValue.value = optionalMaterial.value[isCheck.value];
			priceAddVal.value = tempValue.value.priceAdd;
		}
		if (val.productMaterialPOS[0].type == 5 && inx != 1) {
			signType1.value = true;
			priceAddVal.value = tempValue.value.priceAdd;
		} else {
			signType1.value = false;
		}
		if (val.productMaterialPOS[0].type == 13 && inx != 0) {
			signType2.value = true;
			priceAddVal.value = tempValue.value.priceAdd;
		} else {
			signType2.value = false;
		}
	};
	const signType1 = ref(false);
	const signType2 = ref(false);
	const swarpSite = ref();
	const tempindex = ref(0);
	const updateReqParams = (item, inx) => {
		unlocation();
		activeTags.value = "first";
		currentIndex.value = item;
		tempindex.value = inx;
		emitter.emit("settingId", {
			setid: inx
		});
		loadTopSite();
	};
	const router = useRouter();
	const immediate = (val) => {
		// 百度埋点
		if (sessionStorage.getItem("bd_vid")) {
			const buriedData = [{
				logidUrl: window.location.href,
				newType: 7,
			}]
			baiduClue(buriedData)
		}
		const token = localStorage.getItem("token");
		if (token) {
			const data = {
				orderInfoPO: detailData.value,
				orderProductVO: {
					orderGiftVOS: giftData.value,
					orderMaterialVOS: val,
				},
				productCustomizedMaterialPOS: productCustomizedMaterialPOS.value,
			};
			const temp = JSON.stringify(data);
			router.push({
				path: "/configordersetting",
				query: {
					item: temp,
					productId: route.query.id,
				},
			});
		} else {
			emitter.emit("openLogin", {
				openLogin: true
			});
		}
	};
	const order = ref([{
			id: 1,
			text: "精选正品货源"
		},
		{
			id: 2,
			text: "24H顺丰发货"
		},
		{
			id: 3,
			text: "7天无理由退换"
		},
		{
			id: 4,
			text: "整机3年质保"
		},
	]);
	const showOpen = ref(false);
	emitter.on("openClose", () => {
		showOpen.value = false;
	});
	emitter.on("closeBeside", () => {
		showOpen.value = false;
	});
	const handleOpen = () => {
		showOpen.value = true;
	};
</script>

<style lang="less" scoped>
	.firmCustomize {
		margin-top: 20px;

		.firmCustomize-1 {
			width: 672px;
			/* height: 506px; */
			padding-bottom: 28px;
			background: #f2f2f2;
			border: 1px solid #e5e5e5;

			.firmCustomize-2 {
				width: 102px;
				height: 18px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				font-size: 17px;
				color: #666666;
				display: flex;
				margin: 20px 0 26px 6px;
			}

			.firmCustomize-2-1 {
				width: 32px;
				height: 32px;
				margin: 17px 0 0 21px;
			}

			.firmCustomize-3 {
				width: 630px;
				height: 99px;
				background: #ffffff;
				border-radius: 5px;
				border: 1px solid #d9d9d9;
				margin-left: 20px;
				margin-bottom: 14px;
				position: relative;
			}

			.firmCustomize-3-plus {
				width: 630px;
				height: 99px;
				background: #ffffff;
				border-radius: 5px;
				border: 1px solid #ce1200;
				margin-left: 20px;
				margin-bottom: 14px;
				position: relative;
			}

			.firmCustomize-4 {
				width: 16px;
				height: 16px;
				cursor: pointer;
				position: absolute;
				top: 41px;
				left: 23px;
			}

			.firmCustomize-5 {
				height: 18px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				font-size: 18px;
				color: #333333;
				position: absolute;
				top: 27px;
				left: 61px;
			}

			.firmCustomize-5-plus {
				height: 18px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				font-size: 18px;
				color: #ce1200;
				position: absolute;
				top: 27px;
				left: 61px;
			}

			.firmCustomize-6 {
				width: 321px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				font-size: 14px;
				color: #999999;
				position: absolute;
				top: 59px;
				left: 64px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}

			.firmCustomize-8 {
				width: 100px;
				height: 32px;
				background: #ffffff;
				border-radius: 5px;
				border: 1px solid #aaaaaa;
				font-family: Microsoft YaHei;
				font-weight: 400;
				font-size: 12px;
				color: #666666;
				text-align: center;
				line-height: 30px;
				position: absolute;
				top: 33px;
				right: 31px;

				&:hover {
					color: #ce1200;
					border: 1px solid #ce1200;
					cursor: pointer;
				}
			}

			.firmCustomize-6-1 {
				width: 48px;
				height: 70px;
				position: absolute;
				top: 15px;
				right: 162px;

				.firmCustomize-6-1-1 {
					width: 48px;
					height: 48px;
					border: 1px solid #e1e1e1;
				}

				.firmCustomize-6-1-2 {
					width: 48px;
					height: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					font-size: 12px;
					color: #999999;
					margin-top: 6px;
					text-align: center;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}

			.firmCustomize-9 {
				width: 566px;
				height: 70px;
				display: flex;
				position: absolute;
				top: 92px;
				left: 64px;

				.firmCustomize-9-4 {
					margin-left: 24px;

					.firmCustomize-9-5 {
						width: 48px;
						height: 14px;
						margin-top: 6px;
						text-align: center;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						font-family: Microsoft YaHei;
						font-weight: 400;
						font-size: 12px;
						color: #999999;
					}
				}

				.firmCustomize-9-1 {
					width: 108px;
					height: 70px;
					margin-right: 8px;
					text-align: center;

					.firmCustomize-9-2 {
						width: 48px;
						height: 48px;
						border: 1px solid #e1e1e1;
						margin: 0 auto;
					}

					.firmCustomize-9-3 {
						width: 108px;
						/* height: 13px; */
						font-family: Microsoft YaHei;
						font-weight: 400;
						font-size: 12px;
						color: #999999;
						margin-top: 6px;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
					}
				}
			}
		}

		.safeguardServe {
			width: 672px;
			/* height: 336px; */
			background: #f2f2f2;
			border: 1px solid #e5e5e5;
			margin-top: 30px;
			padding-bottom: 7px;

			.safeguardServe-2 {
				width: 102px;
				height: 18px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				font-size: 17px;
				color: #666666;
				margin: 20px 0 26px 6px;
			}

			.safeguardServe-2-1 {
				width: 32px;
				height: 32px;
				margin: 17px 0 0 21px;
			}

			.safeguardServe-3 {
				width: 630px;
				height: 78px;
				background: #ffffff;
				border-radius: 5px;
				margin-left: 21px;
				border: 1px solid #d9d9d9;
				margin-bottom: 14px;
				position: relative;

				.safeguardServe-4 {
					position: absolute;
					width: 16px;
					height: 16px;
					top: 30px;
					left: 23px;
				}

				.safeguardServe-5 {
					width: 405px;
					height: 21px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					font-size: 18px;
					color: #333333;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					position: absolute;
					top: 26px;
					left: 61px;
				}

				.safeguardServe-6 {
					height: 11px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					font-size: 15px;
					color: #666666;
					position: absolute;
					top: 18px;
					right: 30px;
				}

				.safeguardServe-8 {
					height: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #999999;
					position: absolute;
					top: 38px;
					right: 30px;
				}
			}
		}

		.choose {
			width: 672px;
			/* height: 180px; */
			background: #f2f2f2;
			margin-top: 36px;
			padding-bottom: 22px;
			overflow: hidden;

			.choose-1 {
				font-family: Microsoft YaHei;
				font-weight: bold;
				font-size: 17px;
				color: #333333;
				margin: 23px 0 10px 45px;
			}

			.choose-2 {
				margin-left: 45px;
				margin-bottom: 26px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				font-size: 15px;
				color: #666666;
				display: flex;
				position: relative;

				.choose-3 {
					position: absolute;
				}

				.choose-4 {
					position: absolute;
					right: 52px;
				}
			}
		}
	}

	.shop-container {
		width: 1200px;
		background-color: #eeee;
		margin: 0 0 0 15px;

		img {
			width: 100%;
		}
	}

	.dialog-list {
		::v-deep .el-dialog__title {
			font-size: 15px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
		}

		::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav {
			border: 0;
		}

		::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
			background: #ce1200;
			color: #fff;
		}

		::v-deep .el-tabs__active-bar {
			background-color: #ce1200;
		}

		::v-deep .el-tabs--card>.el-tabs__header {
			border-bottom: 0;
		}

		::v-deep .el-dialog__header {
			padding: 0;
			padding-bottom: 0;
			margin-right: 0;
			padding-top: 52px;
			margin-left: 80px;
		}

		::v-deep .el-dialog__body {
			padding: 0;
		}

		::v-deep .el-dialog__headerbtn {
			position: absolute;
			top: 39px;
			right: 58px;

			.el-dialog__close {
				font-size: 20px;
				color: #999;
			}

			.el-dialog__close:hover {
				color: #ce1200;
			}
		}

		.border {
			width: 1070px;
			height: 1px;
			background: #e1e1e1;
			margin-left: 65px;
			margin-top: 19px;
		}

		.border-content {
			width: 1095px;
			height: 624px;
			margin-left: 65px;
			margin-top: 36;
			overflow-y: scroll;

			.T-J {
				position: relative;
				top: 46px;

				img {
					width: 46px;
					height: 46px;
				}
			}

			.content {
				width: 1070px;
				height: 120px;
				border: 1px solid #d9d9d9;
				display: flex;
				margin-bottom: 12px;
				cursor: pointer;

				&.active {
					border: 1px solid #ce1200;

					.imgVal {
						display: inline-block;
						width: 24px;
						height: 24px;
						background-image: url("./img/gx.png");
						background-repeat: no-repeat;
						background-size: cover;
						position: absolute;
						left: 1110px;
					}
				}

				.content-img {
					margin-top: 8px;
					margin-left: 16px;

					img {
						width: 102px;
						height: 102px;
					}
				}

				.content-text {
					margin-left: 16px;
					margin-right: 118px;

					.content-text-1 {
						width: 450px;
						font-size: 15px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						margin-top: 26px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.content-text-2 {
						width: 600px;
						font-size: 12px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #555555;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.content-text-3 {
						.content-text-3-1 {
							display: inline-block;
							font-size: 12px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #555555;
							position: relative;
							top: 10px;
						}

						.content-text-3-2 {
							display: inline-block;
							font-size: 18px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #555555;
							position: relative;
							top: 10px;
							margin-left: 15px;
						}
					}
				}

				.content-num {
					display: flex;
					margin-top: 44px;

					.num-input-1 {
						display: inline-block;
						width: 100px;
						font-size: 18px;
						text-align: center;
					}

					.num-input {
						position: relative;
						top: -3px;

						.btn_minute {
							width: 32px;
							height: 32px;
							background: #f2f2f2;
							border: 1px solid #d9d9d9;
							cursor: pointer;
						}

						.btn_add {
							width: 32px;
							height: 32px;
							background: #f2f2f2;
							border: 1px solid #d9d9d9;
							cursor: pointer;
						}

						.btn-input {
							width: 52px;
							height: 32px;
							text-align: center;
							padding-bottom: 2px;
							border-top: 1px solid #d9d9d9;
							border-bottom: 1px solid #d9d9d9;
							border-left: 0;
							border-right: 0;
							background-color: #ffffff;
							padding-left: 15px;
						}
					}

					.kernel-i-1 {
						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #ce1200;
						position: relative;
						right: -30px;
						bottom: 0px;

						img {
							display: inline-block;
							width: 8px;
							height: 8px;
							position: relative;
							top: -2px;
						}
					}

					.kernel-i-2 {
						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #04ca17;
						position: relative;
						right: -30px;
						bottom: 0px;

						img {
							display: inline-block;
							width: 7px;
							height: 2px;
							position: relative;
							top: -2px;
						}
					}
				}
			}
		}

		.black {
			width: 1200px;
			height: 80px;
			background: #333333;
			display: flex;
			justify-content: space-between;

			.black-1 {
				margin-left: 65px;

				.black-1-1 {
					display: inline-block;
					margin-top: 33px;
					font-size: 12px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #ffffff;
				}

				.black-1-2 {
					display: inline-block;
					font-size: 24px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #f20a0a;
					margin-left: 25px;
					position: relative;
					top: 4px;

					img {
						display: inline-block;
						width: 8px;
						height: 8px;
						position: relative;
						top: -2px;
					}
				}

				.black-1-3 {
					display: inline-block;
					font-size: 24px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #04ca17;
					margin-left: 25px;
					position: relative;
					top: 4px;

					img {
						display: inline-block;
						width: 7px;
						height: 2px;
						position: relative;
						top: -2px;
					}
				}
			}

			.black-4-4 {
				display: flex;

				.black-3 {
					width: 200px;
					height: 60px;
					line-height: 60px;
					background: #f1f1f1;
					border-radius: 5px;
					font-size: 15px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					text-align: center;
					cursor: pointer;
					margin-top: 9px;
				}

				.black-2 {
					width: 200px;
					height: 60px;
					line-height: 60px;
					background: #ce1200;
					border-radius: 5px;
					font-size: 15px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #ffffff;
					text-align: center;
					cursor: pointer;
					margin-right: 65px;
					margin-top: 9px;
					margin-left: 12px;
				}
			}
		}
	}

	.plusmiuse {
		width: 96px;
		height: 32px;
	}

	.g-name {
		font-size: 22px;
	}

	.g-desc {
		color: #999;
		margin-top: 10px;
	}

	.goods-tabs {
		background: #fff;
	}

	.goods-tabs {
		.good-tabs-one {
			display: flex;

			.untimefix {
				position: fixed;
				top: 105px;
				z-index: 99;
			}

			.goods-text-t {
				margin-bottom: 32px;
				margin-left: 13px;

				.g-name {
					font-size: 17px;
					font-weight: bold;
					color: #111111;
				}

				.g-desc {
					font-size: 13px;
					font-weight: 400;
					color: #666666;
				}

				.g-price {
					margin-top: 36px;
					position: relative;

					span:nth-child(1) {
						font-family: Microsoft YaHei;
						font-weight: bold;
						font-size: 18px;
						color: #ce1200;
						padding-right: 5px;
					}

					span:nth-child(2) {
						font-weight: bold;
						font-size: 26px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #ce1200;
					}

					.g-price-1 {
						position: absolute;
						left: 530px;
						top: 9px;
						cursor: pointer;
						font-family: Microsoft YaHei;
						font-weight: 400;
						font-size: 15px;
						color: #ce1200;
					}

					.g-price-2 {
						width: 12px;
						position: absolute;
						left: 626px;
						top: 14px;
						cursor: pointer;
					}
				}
			}

			.coupon {
				display: flex;
				justify-content: space-between;
				margin-left: 13px;
				height: 30px;
				width: 638px;
				cursor: pointer;

				.coupon-left {
					display: flex;

					.coupon-li {
						margin-right: 10px;
						background-image: url(./img/coupon_background.png);
						background-size: cover;
						//width: 118px;
						width: 130px;
						height: 30px;
						line-height: 30px;
						text-align: center;
						font-family: Microsoft YaHei;
						font-weight: 400;
						font-size: 13px;
						color: #ce1200;
					}
				}

				.coupon-right {
					span {
						margin-right: 6px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						font-size: 15px;
						color: #ce1200;
					}

					img {
						width: 8px;
						height: 12px;
					}
				}
			}

			.commodity-tab {
				width: 672px;
				margin-top: 16px;

				.tags {
					flex: 1;
					display: flex;

					.dt {
						font-weight: bold;
						width: 100px;
						text-align: right;
						line-height: 42px;
					}

					.dd {
						flex: 1;
						display: flex;
						flex-wrap: wrap;

						>a {
							height: 44px;
							margin-left: 12px;
							margin-bottom: 12px;
							border-radius: 4px;
							border: 1px solid #e4e4e4;
							color: #555;
							text-align: center;
							line-height: 40px;
							padding: 0 20px;

							&:hover {
								border-color: #ce1200;
								//background: lighten(#ce1200, 50%);
								color: #ce1200;
							}

							&.active {
								border-color: #ce1200;
								//background: lighten(#ce1200, 50%);
								color: #ce1200;
							}
						}
					}
				}
			}

			.tabs-tab-two {
				margin-left: 20px;

				.tabs-tab-two-1 {
					width: 68px;
					height: 18px;
					font-size: 17px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #666666;
				}

				.container {
					width: 100%;

					.container-top-t {
						.container-top-left {

							// 核心系统
							.title-text {
								height: 20px;
								display: flex;
								margin-bottom: 3px;
								margin-left: 13px;

								.text-span {
									margin-top: 3px;
									left: 0;
									border-left: 3px solid #ce1200;
								}

								.container-top-l {
									font-size: 16px;
									font-weight: 400;
									color: #333333;
									margin-bottom: 3px;
									margin-left: 6px;
								}
							}

							.container-top-l-lx {
								width: 100%;

								.img-top-content {
									/* width: 405px; */
									display: flex;

									.img-top-content-text {
										display: flex;
										margin-left: 130px;
										height: 45px;

										.img-top-content-l {
											width: 76px;
											line-height: 40px;
											height: 40px;
											font-size: 15px;
											font-weight: 400;
											color: #333333;
										}

										.img-top-content-r {
											width: 405px;
											height: 36px;
											line-height: 38px;
											display: flex;
											justify-content: space-between;
											margin-left: 32px;

											.img-top-text-r {
												.img-top-content-r-text {
													font-size: 11px;
													font-weight: 400;
													color: #555555;
												}
											}

											.num-inputs {
												display: flex;
												margin-top: 3px;

												.btn_minute {
													width: 32px;
													height: 32px;
													background: #f2f2f2;
													border: 1px solid #d9d9d9;
													cursor: pointer;
												}

												.btn_add {
													width: 32px;
													height: 32px;
													background: #f2f2f2;
													border: 1px solid #d9d9d9;
													cursor: pointer;
												}

												.btn-input {
													width: 54px;
													height: 32px;
													text-align: center;
													border-top: 1px solid #d9d9d9;
													border-bottom: 1px solid #d9d9d9;
													border-left: 0;
													border-right: 0;
													background-color: #ffffff;
													padding-left: 15px;
												}
											}

											.img-top-content-m {
												width: 405px;
												height: 40px;
												line-height: 40px;
												border: 1px solid #d9d9d9;
												display: flex;

												.img-top-content-m-1 {
													padding-left: 10px;
													font-size: 11px;
													font-family: Microsoft YaHei;
													font-weight: 400;
													width: 350px;
													color: #555555;
													cursor: pointer;
												}

												.img-top-content-m-1-1 {
													padding-left: 10px;
													width: 350px;
													font-size: 11px;
													font-family: Microsoft YaHei;
													font-weight: 400;
													color: #999999;
													cursor: pointer;
												}

												.img-top-content-m-2 {
													font-size: 11px;
													font-family: Microsoft YaHei;
													font-weight: 400;
													color: #555555;
												}

												.img-top-content-m-2-2 {
													font-size: 11px;
													font-family: Microsoft YaHei;
													font-weight: 400;
													color: #555555;
													margin-left: 15px;
												}

												.img-top-content-m-3 {
													width: 15px;
													height: 8px;
													margin: 0 0 3px 10px;
												}
											}

											.input-number-num {
												.num-span {
													font-size: 11px;
													font-weight: 400;
													color: #555555;
												}
											}
										}
									}

									.img-top-content-plus {
										/* font-family: PingFang SC, Helvetica Neue, Helvetica,
                      Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif; */
										font-size: 13px;
										font-weight: 800;
										font-weight: bold;
										color: #ce1200;
										height: 8px;
										line-height: 40px;
										margin-left: 10px;
										/* width: 200px; */
									}

									.img-top-content-reduce {
										/* font-family: PingFang SC, Helvetica Neue, Helvetica,
                      Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif; */
										font-size: 13px;
										font-weight: 800;
										font-weight: bold;
										color: #04ca17;
										height: 8px;
										line-height: 40px;
										margin-left: 10px;
										/* width: 200px; */
									}
								}
							}
						}
					}
				}

				::v-deep .el-tabs--border-card>.el-tabs__content {
					padding: none;
				}
			}

			.g-price-btn {
				.g-price {
					display: flex;
					margin-top: 32px;

					.configuration {
						display: inline-block;
						width: 136px;
						height: 40px;
						line-height: 40px;
						text-align: center;
						margin: auto;
						margin-right: 57px;
						font-size: 14px;
						font-weight: 400;
						color: #555555;
						background-color: #f2f2f2;
					}

					p:nth-child(2) {
						margin-top: 10px;

						span:nth-child(1) {
							&::before {
								content: "¥";
								font-size: 14px;
								margin-right: 5px;
							}

							&:nth-child(1) {
								color: #ce1200;
								margin-right: 10px;
								font-size: 22px;
								font-weight: bold;
							}
						}

						&:nth-child(2) {
							font-size: 11px;
							font-weight: 400;
							color: #555555;

							b {
								color: #ce1200;
							}
						}
					}
				}

				.box-btn {
					width: 100%;
					display: flex;
					margin-top: 32px;

					.box-btn-l {
						width: 340px;
						height: 56px;
						background: #ce1200;
						border-radius: 5px;
						color: #fff;
						font-size: 15px;
						margin-top: 2px;
						border: none;
					}

					.box-btn-2 {
						width: 332px;
						height: 54px;
						border-radius: 5px;
						border: 1px solid #ce1200;
						font-family: Microsoft YaHei;
						font-weight: 400;
						font-size: 15px;
						color: #ce1200;
						cursor: pointer;
						text-align: center;
						line-height: 54px;
						margin-right: 11px;
						margin-top: 3px;
					}

					.box-btn-r {
						width: 340px;
						height: 60px;
						background-repeat: no-repeat;
						/* background: #f2f2f2;
           border: 1px solid #d9d9d9;
           border-radius: 5px;
           color: #333333;
           font-size: 15px; */
						/* background-image: url("../../../assets/images/dtt2.gif"); */
						cursor: pointer;
					}
				}

				.order-t {
					margin-top: 23px;

					span:nth-child(1) {
						margin-left: 4px;
					}

					span:nth-child(2) {
						font-size: 12px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #555555;
						margin-left: 6px;
					}
				}

				.order-list {
					display: flex;
					margin-top: 22px;

					li {
						margin-right: 30px;

						img {
							width: 16px;
							height: 16px;
							margin-bottom: 3px;
							margin-left: 5px;
						}

						span {
							font-size: 12px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #999999;
							margin-left: 5px;
						}
					}
				}
			}
		}

		.detailCenter {
			width: 157px;
			height: 46px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			font-size: 26px;
			color: #333333;
			text-align: center;
			margin: 0 auto;
			border-bottom: 4px solid #ce1200;
		}

		.goods-tabs-1 {
			width: 1230px;
			margin-top: 80px;

			.el-tabs__active-bar {
				height: 0;
			}
		}
	}

	::v-deep .el-tabs__nav-wrap::after {
		background-color: transparent;
	}

	::v-deep .el-tabs__header {
		margin: 0;
		background: #f2f2f2;
	}

	::v-deep .el-tabs__content {
		padding: 0;
	}

	::v-deep .el-tabs__item {
		font-size: 17px;
		font-weight: 400;
	}

	::v-deep .el-tabs__item.is-active {
		color: #ffffff;
		background: #ce1200;
	}

	::v-deep .el-tabs__item:hover {
		color: #ce1200;
	}

	::v-deep .el-tabs__item:hover {
		background: #ce1200;
		color: #fff !important;
	}

	.app-header-sticky {
		width: 1920px;
		height: 64px;
		position: fixed;
		left: 50%;
		top: 71px;
		z-index: 99;
		background: #ffffff;
		box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.15);
		opacity: 0;
		transform: translateX(-50%);

		&.show {
			//transform: none;
			opacity: 1;
			transition: all 1s ease;
		}

		.container-btn {
			display: flex;
			justify-content: space-around;
			background: #ffffff;
			height: 64px;
			line-height: 64px;

			.container-left {
				display: flex;
				margin-left: -46px;

				span {
					font-size: 15px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #111111;
					margin-left: 15px;
					width: 277px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}

			.container-middle {
				display: flex;
				margin-left: -630px;

				.container-middle-1 {
					cursor: pointer;
					width: 60px;
					height: 64px;
					font-size: 15px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #666666;
					margin-right: 20px;

					&.active {
						color: #ce1200;
						border-bottom: 2px solid #ce1200;
					}

					&:hover {
						color: #ce1200;
						border-bottom: 2px solid #ce1200;
					}
				}
			}

			.container-right {
				display: flex;
				align-items: center;
				margin-left: -670px;

				p:nth-child(1) {
					width: 89px;
					height: 15px;
					line-height: 15px;
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #ce1200;
				}

				p:nth-child(2) {
					padding-left: 11px;
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #ce1200;
				}

				.r-btn {
					padding-left: 23px;
					margin-bottom: 8px;

					::v-deep .el-button {
						width: 100px;
						height: 36px;
						background: #ce1200;
						border-radius: 18px;
						font-size: 15px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
						border: #ce1200ff;
						border-color: #ce1200ff;
					}
				}
			}
		}
	}
</style>