<template>
  <div class="goods-image">
    <div class="middle" ref="middleEle">
      <div style="width: 100%;height: 100%;position: relative;">
        <div class="goodCompare" @click="goodsCompare">
          <img src="./image/goods.png" class="goodCompare-1">
          <div class="goodCompare-2">商品对比</div>
        </div>
        <videoPlay
          v-show="images[current]?.isimg === false"
          ref="aplayVideo"
          v-bind="playerOptions"
          :playsinline="false"
          :src="images[current]?.img"
        ></videoPlay>
        <el-image
          v-if="images[current]?.isimg === true"
          class="bigimg"
          :src="images[current]?.img"
          :preview-src-list="srcList"
          :initial-index="images[0]?.isimg === false ? current - 1 : current"
        />
      </div>
    </div>
    <div>
      <div class="recommendPage">
        <swiper
          :modules="modules"
          class="swiper-wrapper"
          :slides-per-view="5"
          :spaceBetween="8"
          :Navigation="swiperOption"
          @swiper="onSwiper"
        >
          <swiper-slide
            v-for="(item, index) in images"
            :key="index"
            @mouseenter="current = index"
            class="swiperlide"
          >
            <div v-if="item.image" style="margin-top: 1px">
              <el-image :src="require('../img/play.png')" class="playImage" />
              <img
                :src="item.image[0].img"
                style="position: relative; top: -29px"
                class="swipeimg"
                :class="{ swiperActive: current == index }"
                @click="changeImg(item, index)"
              />
            </div>
            <div v-else>
              <img
                :src="item.img"
                class="swipeimg"
                :class="{ swiperActive: current == index }"
                @click="changeImg(item, index)"
              />
            </div>
          </swiper-slide>
        </swiper>
        <div
          :class="
            isdis1 == 0
              ? 'swiper-button-prev swiper-button-disabled'
              : 'swiper-button-prev'
          "
          @click="bannerSwiperPrev"
        ></div>
        <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
        <div
          :class="
            isdis2 == 0
              ? 'swiper-button-next swiper-button-disabled'
              : 'swiper-button-next'
          "
          @click="bannerSwiperNext"
        ></div>
      </div>
    </div>

    <div id="zoomBottom">
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
// import { useMouseInElement } from "@vueuse/core";
import { videoPlay } from "vue3-video-play"; // 引入视频播放的组件
import "vue3-video-play/dist/style.css";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";
import { EffectFade, Autoplay, Pagination, Navigation } from "swiper";
const modules = [Autoplay, Navigation, Pagination, EffectFade];
import { listProductStandardNew } from "@/api/details";
import { useRouter } from "vue-router";
import emitter from "@/utils/eventBus";
import { Encrypt, Decrypt } from "@/utils/aes";
// const props = defineProps({
//   images: Array,
//   default: () => [],
// });
const isdis1 = ref(0);
const isdis2 = ref(1);
const setid = ref(0);

const activeIn = ref(0);
const mainImgUrl = ref("");
const changeImg = (item, index) => {
  activeIn.value = index;
  mainImgUrl.value = item;
  current.value = index;
};
const srcList = ref([]);
const settingList = ref([]);
const status = ref(null);
const router = useRouter();
const onSwiper = (swiper) => {
  status.value = swiper;
};
// const menuPlayer = (event) => {
//   console.log(event);
// };

const bannerSwiperPrev = () => {
  // status.value.slidePrev();
  if (!status.value.slidePrev()) {
    isdis1.value = 0;
  } else {
    isdis2.value = 1;
  }
};
emitter.on('settingId',(n) => {
  setid.value = n.setid
})
const goodsCompare = () => {
	// 百度埋点
	if(sessionStorage.getItem("bd_vid")){
		const buriedData = [
			{
				logidUrl: window.location.href,
				newType: 20,
			}
		]
		baiduClue(buriedData)
	}
  router.push({
      path: "/goodsCompare",
      query: {
        configId: settingList.value[setid.value].configId,
        productId: route.query.id,
      },
    });
}
const bannerSwiperNext = () => {
  // status.value.slideNext();
  if (status.value.slideNext()) {
    isdis1.value = 1;
  } else {
    isdis2.value = 0;
  }
};
const swiperOption = ref({});
const getData = () => {
  swiperOption.value = {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };
};
onMounted(() => {
  getData();
});
const playerOptions = reactive({
  width: "100%", //播放器高度
  height: "100%", //播放器高度
  color: "#409eff", //主题色
  title: "", //视频名称
  webFullScreen: false, //网页全屏
  speed: true, //是否支持快进快退
  currentTime: 0, //跳转到固定播放时间(s)
  muted: false, //静音
  preload: "auto",
  fluid: true,
  autoPlay: true, //自动播放
  loop: false, //循环播放
  mirror: false, //镜像画面
  control: true, //是否显示控制器
  ligthOff: false, //关灯模式
  volume: 0.3, //默认音量0-1
  language: "zh-CN",
  // src: src, //视频源
  // poster: poster, //封面
  speedRate: [1.0, 1.25, 1.5, 2.0], // 可选的播放速度
  controlBtns: [
    "audioTrack", //音轨切换按钮
    "quality", //视频质量切换按钮
    "volume", //音量
    // "setting",//设置
    "fullScreen", //全屏按钮
  ],
});
const current = ref(0);
const shiftOne = (val) => {
  srcList.value[val - 1] = srcList.value.splice(
    srcList.value[0],
    1,
    srcList.value[val - 1]
  );
};
const middleEle = ref(null);
import { useRoute } from "vue-router";
import {baiduClue} from "@/api/shop";
const route = useRoute();
const images = ref([]); // 总数据
const videos = ref([]); // 视频
const pictures = ref([]); // 视频
const ArrayList = ref([]); // 图片
const getDetailGoods = async (productId = route.query.id) => {
  try {
    const res = await listProductStandardNew(productId);
    let data = Decrypt(res.data)
    let secret = JSON.parse(data)
    if (res.code === 200) {
      settingList.value = secret.configTypeNewVOList;
      ArrayList.value = secret.productInfoPO.publicizePictures;
      // console.log(videos.value.length, "查看长度");
      if (secret.productInfoPO.videos) {
        videos.value = secret.productInfoPO.videos;
        videos.value[0].image = secret.productInfoPO.pictures;
        images.value = videos.value.concat(ArrayList.value);
      } else {
        images.value = secret.productInfoPO.publicizePictures;
      }
      console.log(ArrayList.value, videos.value);
      console.log(images.value, "合并之后的数据");
      images.value.forEach((item, index) => {
        if (item.isimg) {
          srcList.value.push(item.img);
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
};
onMounted(() => {
  getDetailGoods();
});
if(document.documentElement.clientHeight > 1000){
  playerOptions.width = "680px"
  playerOptions.height = "680px"
}
</script>
<style scoped lang="less">
/deep/ .el-image-viewer__close {
    width: 2.75rem;
    height: 2.75rem;
    font-size: 1.5rem;
    color: #fff;
    background-color: var(--el-text-color-regular);
    border-color: #fff;
    margin-top: 36px !important;
}
video {
  background-color: #000;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.5;
  cursor: auto;
  pointer-events: none;
}
.swiper-button-disabled {
  cursor: auto;
  pointer-events: none;
  width: 30px;
  height: 80px;
  background: #f2f2f2;
  border-radius: 5px;
  color: #c1c1c1;
}
.swiper-button-next {
  right: -32px !important;
  width: 30px;
  height: 80px;
  top: 0px;
  background: #eee;
  border-radius: 5px;
  color: #b1b1b1;
}
.swiper-button-next:hover {
  background: #999;
  color: #fff;
}
.swiper-button-prev {
  left: -34px !important;
  top: 0px;
  width: 30px;
  height: 80px;
  background: #eee;
  border-radius: 5px;
  color: #b1b1b1;
}
.swiper-button-prev:hover {
  background: #999;
  color: #fff;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 30px;
  font-weight: 800;
}

.recommendPage {
  width: 448px;
  position: relative;
  margin-top: 20px;
  left: 32px;
  .swiper-wrapper {
    /* width: 448px; */
    /* padding: 0 31px; */
  }
  .swiper-slide {
    height: 80px;
    width: 80px;
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev {
    left: 0;
  }
  // .swiperActive {
  //   border: 2px solid #ce1200;
  // }
  .swiperlide {
    width: 83.5px !important;
    // border: 1px solid #d9d9d9;
    border-radius: 5px;
    // &:hover {
    //   border: 2px solid #ce1200;
    // }
    .playImage {
      position: relative;
      left: 28px;
      top: 25px;
      z-index: 1;
    }
    .swipeimg {
      box-sizing: border-box;
      cursor: pointer;
      width: 80px;
      height: 80px;
      border-radius: 5px;
      border: 1px solid #d9d9d9;
      &:hover {
        border: 2px solid #ce1200;
      }
    }
    // .swipeimg:hover {
    //   border: 2px solid #ce1200;
    // }
  }
}
.goods-image {
  width: 510px;
  height: 510px;
  position: relative;
  display: flex;
  flex-direction: column;
  // z-index: 500;
  .large {
    position: absolute;
    top: 0;
    left: 492px;
    width: 510px;
    height: 510px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-repeat: no-repeat;
    background-size: 800px 800px;
    background-color: #f8f8f8;
  }
  .middle {
    width: 510px;
    height: 510px;
    background: #f5f5f5;
    position: relative;
    /* cursor: pointer; */
    /* cursor: move; */
    .layer {
      width: 280px;
      height: 200px;
      background: rgba(0, 0, 0, 0.2);
      left: 0;
      top: 0;
      position: absolute;
    }
    .goodCompare{
      width: 128px;
      height: 36px;
      background: #F2F2F2;
      z-index: 2;
      border-radius: 18px;
      border: 1px solid #EEEEEE;
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer;
      .goodCompare-1{
        position: absolute;
        left: 15px;
        top: 4px;
      }
      .goodCompare-2{
        width: 60px;
        height: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 15px;
        color: #333333;
        position: absolute;
        left: 46px;
        top: 6px;
      }
    }
    .bigimg {
      width: 510px;
      height: 510px;
    }
    .bigimg:hover {
      ::v-deep .el-image__preview {
        cursor: url("./image/magnifying.png") 16 16, auto;
      }
    }
  }
  #zoomBottom {
    width: 480px;
    margin-top: 10px;
    a {
      width: 10px;
      height: 54px;
      // border: 1px solid #ccc;
      // background: #ebebeb;
      text-align: center;
      line-height: 54px;
      float: left;
      text-decoration: none;

      &:first-child {
        margin-right: 4px;
      }
    }

    #picList {
      width: 450px;
      height: 68px;
      float: left;
      overflow: hidden;
      position: relative;

      ul {
        white-space: nowrap;
        font-size: 0px;
        position: absolute;
        left: 25px;
        transition: 0.5s;

        li {
          width: 72px;
          height: 68px;
          margin-right: 10px;
          display: inline-block;
          &:hover,
          &.active {
            border: 2px solid #ce1200;
          }

          img {
            width: 68px;
            height: 68px;
          }
        }
      }
    }
  }
}
</style>
